import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import TooltipTitle from 'components/Common/TooltipTitle';
import ResourceSelection from 'components/Database/ResourcesSelection';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { NODE_TYPES } from 'utils/constants';
import { testOptionalIntegerInput } from 'components/Nodes/Common/Components/utils';
import Actions from '../../Common/Actions';
import { nodeTypeDefault, Paper } from '../../Styles';

export const CreateHorizonPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  input_path: '',
  format: 'odt',
  horizons_as_rows: true,
  //separator in initial values needes escape character
  // eslint-disable-next-line prettier/prettier
  separator: '\\s+',
  skiprows: 0,
  downsample_rate: 1,
  advanced_parameters: false,
  nodeType: NODE_TYPES.CREATE_HORIZON
};
const file_requirements_formats = [
  '[Horizon Name, Inline, Crossline, Time or Depth]',
  '[Inline, Xline, Horizon1, Horizon2, Horizon3, ……….]'
];
function CreateHorizon({ data, isCreated = false, onCancel, onSave, onShowLogs }) {
  const classes = nodeTypeDefault();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);
  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeInteger = (prop) => (event) => {
    if (testOptionalIntegerInput(event.target.value)) {
      return setValues({ ...values, [prop]: Number(event.target.value) });
    }
  };

  const handleResourceChange = (prop) => (value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSliderChange = (prop) => (event, newValue) => {
    setValues({ ...values, [prop]: newValue });
  };

  const handleSave = () => {
    setSaveNode(true);
    if (values.name !== '') {
      onSave(values);
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  const handleCheckboxChange = (prop) => (event) => {
    const value = event.target.checked;
    setValues({ ...values, [prop]: value });
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, []);

  const getFileFormatHelperText = (format, horizons_as_rows) => {
    if (format === 'txt') {
      return `File Format Requirements are ${
        values.horizons_as_rows === undefined || values.horizons_as_rows
          ? file_requirements_formats[0]
          : file_requirements_formats[1]
      }`;
    }
    if (format === 'odt') {
      return `File Format Requirements are ${file_requirements_formats[0]}`;
    }
    return '';
  };
  return (
    <div data-testid="CreateHorizon" className={classes.root}>
      <Paper>
        <TooltipTitle>Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Horizon Selection</TooltipTitle>
        <FormControl fullWidth>
          <ResourceSelection inputPath={values.input_path} onChange={handleResourceChange('input_path')} />
          {getFileFormatHelperText(values.format, values.horizons_as_rows) && (
            <Typography>{getFileFormatHelperText(values.format, values.horizons_as_rows)}</Typography>
          )}
        </FormControl>
      </Paper>
      <Paper>
        <div>
          <TooltipTitle>File Format</TooltipTitle>
          <FormControl fullWidth>
            <Select
              labelId="format-select-label"
              id="format-select"
              onChange={handleChange('format')}
              value={values.format}
            >
              <MenuItem value="odt">OpendTect</MenuItem>
              <MenuItem value="hrs">HampsonRussell</MenuItem>
              <MenuItem value="txt">General Text File</MenuItem>
            </Select>
          </FormControl>
        </div>
        {values.format === 'txt' && (
          <>
            <div style={{ marginTop: '5px' }}>
              <TooltipTitle tooltip="Data columns format">Data Format </TooltipTitle>
              <FormControl fullWidth>
                <Select
                  labelId="file-requirements-select-label"
                  id="file-requirements-select"
                  onChange={(e) => {
                    if (e.target.value === file_requirements_formats[0])
                      setValues((prevstate) => ({ ...prevstate, horizons_as_rows: true }));
                    else setValues((prevstate) => ({ ...prevstate, horizons_as_rows: false }));
                  }}
                  value={
                    values.horizons_as_rows === undefined || values.horizons_as_rows
                      ? file_requirements_formats[0]
                      : file_requirements_formats[1]
                  }
                >
                  <MenuItem value={file_requirements_formats[0]}>{file_requirements_formats[0]}</MenuItem>
                  <MenuItem value={file_requirements_formats[1]}>{file_requirements_formats[1]}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ marginTop: '5px' }}>
              <TooltipTitle>File Delimiter</TooltipTitle>
              <FormControl fullWidth>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={values.separator}
                  onChange={handleChange('separator')}
                >
                  <MenuItem value="\t">Tab</MenuItem>
                  <MenuItem value=",">Comma</MenuItem>
                  <MenuItem value="\s+">Whitespace</MenuItem>
                  <MenuItem value=";">Semicolon</MenuItem>
                </Select>
              </FormControl>
            </div>
          </>
        )}
        {(values.format === 'txt' || values.format === 'odt') && (
          <div style={{ marginTop: '5px' }}>
            <TooltipTitle>Skip Rows</TooltipTitle>
            <TextField
              margin="dense"
              id="skiprows"
              type="text"
              fullWidth
              InputProps={{ inputProps: { inputMode: 'numeric', pattern: '[0-9]*' } }}
              value={values.skiprows}
              onChange={handleChangeInteger('skiprows')}
            />
          </div>
        )}
      </Paper>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.advanced_parameters}
            value={values.advanced_parameters}
            onChange={handleCheckboxChange('advanced_parameters')}
            name="advanced_parameters"
          />
        }
        label="Configure Advanced Parameters"
      />
      {values.advanced_parameters && advancedParametersSection({ values, handleSliderChange })}
      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}

function advancedParametersSection({ values, handleSliderChange }) {
  return (
    <div>
      <Paper>
        <TooltipTitle>Downsample Rate</TooltipTitle>
        <Slider
          value={values.downsample_rate}
          onChange={handleSliderChange('downsample_rate')}
          min={0.05}
          max={1}
          step={0.05}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
      </Paper>
    </div>
  );
}

CreateHorizon.propTypes = CreateHorizonPropTypes;
CreateHorizon.defaultProps = {};

export default CreateHorizon;
